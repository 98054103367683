import React from 'react';
import { TextField, IconButton, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import '../styles/ChatComponent.css';

const ChatComponent = ({ inputText, setInputText, handleSend }) => {
  return (
    <Box className="chat-box">
      <TextField
        variant="standard"
        fullWidth
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Type your message here..."
        size="medium"
        className="chat-input"
      />
      <IconButton
        onClick={handleSend}
        color="primary"
        disabled={!inputText.trim()}
        className="send-button"
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export default ChatComponent;