import '@fontsource/roboto';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; import './styles/main.css';
import './styles/App.css';
import React, { useState } from 'react';
import { apiGet, apiPost } from './api';
import AppBarWithLogo from './components/AppBarWithLogo';
import ChatComponent from './components/ChatComponent';
import MessageBubbleComponent from './components/MessageBubbleComponent';

const speak = (message) => {
  const utterance = new SpeechSynthesisUtterance(message);
  const voices = window.speechSynthesis.getVoices();
  utterance.voice = voices.find(voice => voice.name === 'Google US English');
  window.speechSynthesis.speak(utterance);
};

function App() {

  const [inputText, setInputText] = useState('');
  let [conversation, setConversation] = useState([]);

  setConversation = (message) => {
    conversation.push(message);
  }

  // Handle sending the message
  const handleSend = async () => {
    if (inputText.trim()) {
      try {
        setConversation({ "role": "user", "content": [{ "text": inputText }] });
        await apiPost('/general_chat', { "content": conversation }).then((response) => {
          // TODO: is this how we want to do this?
          setConversation({ "role": "assistant", "content": [{ "text": response }] });
        });
        setInputText(''); // Clear the input box after sending
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };


  return (
    <div className="App">
      <header className="App-header">
        <ThemeProvider theme={theme}>
          <AppBarWithLogo />
          {
            // only display the conversation when there is a conversation to display
            conversation.length > 0 ?
              <div className="overlay">
                <div className="chat-container">
                  {conversation.map((message, index) => (
                    <MessageBubbleComponent
                      key={index}
                      role={message.role}
                      content={message.content}
                    />
                  ))}
                </div>
              <ChatComponent
                inputText={inputText}
                setInputText={setInputText}
                handleSend={handleSend} // Pass handleSend as a prop
              />
            </div>
            :
            <div className="mainPageLayout">
              <div className="mainPageTextLayout">
                <text className="mainPageTitleFont">Welcome to EduNova</text>
                <text className="mainPageParagraphFont">EduNova provides a modern solution to educators</text>
                <text className="mainPageParagraphFont">in understanding and managing the classroom.</text>
              </div>
              <ChatComponent
                inputText={inputText}
                setInputText={setInputText}
                handleSend={handleSend} // Pass handleSend as a prop
              />
            </div>
          }
        </ThemeProvider>
      </header>
    </div>
  );
}

export default App;



