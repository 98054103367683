import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#232030',  // Your primary color
    },
    secondary: {
      main: '#2ecc71',  // Your secondary color
    },
    background: {
      default: '##ECE6F0',  // Background color
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontSize: '2rem',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  // You can add other customizations like spacing, breakpoints, etc.
});

export default theme;
