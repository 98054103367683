import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function AppBarWithLogo() {
  // handleClick function to handle click event when logo is clicked 
  const handleClick = () => {
    // Redirects to the homepage when clicked
    window.location.href = '/';  // This changes the URL to the homepage
  };

  return (
    <AppBar position="fixed" color="primary">
      <Toolbar>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" color="inherit" onClick={handleClick} style={{ cursor: 'pointer' }}>
            EduNova
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarWithLogo;

