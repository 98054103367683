import React from 'react';
import '../styles/MessageBubbleComponent.css';

const MessageBubbleComponent = ({ role, content }) => {
  return (
    // The message bubble container has a dynamic class based on the role (user or assistant)
    <div className={`message-bubble ${role === "user" ? "user-message" : "assistant-message"}`}>

      {/* Display the sender's label, 'You' for the user or 'Cosmo' for the assistant */}
      <strong>{role === "user" ? "You" : "Cosmo"}:</strong>{' '}

      {/* Map over the content array (for multiple parts of a message) and render each part */}
      {content.map((part, idx) => (
        <span key={idx}>{part.text}</span>
      ))}
    </div>
  );
};

export default MessageBubbleComponent;
